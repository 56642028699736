/*
*
* Tables ***********************************************************************
*
*/
table {
    font-size: $base-font-size;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
}

table td, table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eceeef;
}
/*
*
* Charts ***********************************************************************
*
*/
.chart {
    max-width: 900px;
    padding: 50px 0;
    margin: 0 auto;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
/*
*
* Icons ***********************************************************************
*
*/
.icon {
    width: 100px;
    padding: 10px;
    height: 123px;
}
i.fa {
    font-size: 30px;
    color: #cfd2d3;
}
/*********************** project repos *********************/

.search-repos {
    padding: 0 20px;
    padding-bottom: 50px;
    width: 460px;
    margin: 0 auto;
}
.label {
    font-family: Geomanist;
    font-size: 20px;
    font-weight: 500;
    color: #576167;
}
input.form-control.mr-sm-2 {
    width: 100%;
    height: 44px;
    border-radius: 2px;
    background-color: #f3f4f4;
    border: solid 1px rgba(16, 30, 38, 0.2);
}
.repository {
    background: #f1f1f1;
    margin: 0 0 10px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 10em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    & .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #576167;
    }
}
.post-meta {
    font-size: 16px;
    color: #101e26;
}
li.posts {
    margin: 40px 0 6px 0;
}
li.posts span {
    color: 70787d;
    font-size: 16px;
}

/********************** xtras *********************/

.album {
    min-height: 50rem;
    /* Can be removed; just added for demo purposes */
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #f7f7f7;
}
.card {
    float: left;
    width: 300px;
    padding: .75rem;
    margin-bottom: 2rem;
    border: 0;
}
.card > img {
    margin-bottom: .75rem;
}
.card-text {
    font-size: 85%;
}
/******************************************** bonus ****************************************/
body.ian {
    background: #000;
}

.post-meta {
    padding: 20px 0 20px 0;
}
/*
*
* bootstrap overrides
*
*/
a:not([href]):not([tabindex]) {
    text-decoration: none;
    color: #fff;
}
.modal-content {
	background: #000;
}
.modal-header {
	border-bottom: 1px solid #2d2929;
}
.modal-footer {
	border-top: 1px solid #2d2929;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
    background: #020304;
    opacity: 0.9;
}
.form-control {
    background-color: rgba(255, 255, 255, 0.47)!important;
    color: #fff;
    width: 460px;
    margin: 0 auto;
    &:focus {
        background: #fff;
        border-color: #464a4c;
    }
}
/*
*
* temporary overrides
*
*/
p.timeline-Tweet-text {
    font-size: 12px!important;
}
ul.navbar-nav.mr-auto.justify-content-end  {
    padding: 10px;
}
.twitter {
    border-bottom: 1px solid #ccc;
    width: 650px;
    height: 700px;
    overflow: scroll;
    margin: 0 auto;
}