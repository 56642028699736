/*
*
* globals
*
*/

// $bg-body: linear-gradient(132deg, rgba(255, 255, 255, 0.1), rgba(22, 35, 43, 0));
$bg-body-home: #101e26;
$bg-body: #fff;
$bg-feature: linear-gradient(132deg, rgba(255, 255, 255, 0.1), rgba(22, 35, 43, 0));
$bg-subheader: #f1f1f1;

$bg-nav: #101e26;
$nav-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
$nav-links: #cfd2d3;
$nav-height:   64px;

$bg-footer: #fff;

$padding: 80px;
$full-width: 100%;

/*
*
* typography
*
*/

$font: 'Open Sans', sans-serif;
$base-font-size: 14px;
$base-font-color: #9fa5a8;
$bold: bold;
$lead: 18px;


$headings: #000;

$h1: 36px;
$h2: 30px;
$h3: 24px;

$primary-links: #2089C4;

/*
*
* colors
*
*/

$white: #fff;