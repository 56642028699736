/*
*
* Typography  ***********************************************************************
*
*/
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $headings;
    font-family: 'Geomanist Medium', sans-serif;
    line-height: 30px;
}
h1 {
  font-size: $h1;
}
h2 {
  font-size: $h2;
}
h3 {
  font-size: $h3;
}
ul li {
      font-size: $base-font-size;
      line-height: 1.86;
      text-align: left;
      list-style-type: none;
}
p {
    font-size: $base-font-size;
    text-align: left;
    color: #576167;
    line-height: 32px;
}
.lead {
    line-height: 36px;
}
.heading-center {
  padding: 60px 0 100px 0;
  text-align: center;
  font-weight: bold;
}
.banner-text {
  max-width: 620px;
  padding: 0 30px 10px 10px;
  h1 {
    color: $white;
    font-family: 'Geomanist Bold', sans-serif;
  }
  p {
    color: #cfd2d3;
    font-size: 18px;
    text-align: center;
    line-height: 32px;
  }
}