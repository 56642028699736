/*
*
* utilities
*
*/
table {
    margin-bottom: 3rem;
    border: 1px solid #f1f1f1;
}
table thead {
    background: #f5f5f5;
    color: #666869;
}
.hide {
    transition: all 200ms ease-out;
    opacity: 0;
}
.show {
    opacity: 1;
    transition: all 200ms ease-in;
}
.block {
    padding: 20px 50px 25px 0px;
}
.block-alt {
    padding: 100px 0 0 0;
}
.block-alternate {
    padding: 50px 0 100px 0;
}
.block-vertical {
    min-height: 600px;
    padding: 50px 85px 50px 40px;
    &:first-of-type {
        min-height: 600px;
        border-right: 1px solid #000;
    }
}
.break {
    height: 50px;
    width: 100%;
}

.center {
    text-align: center;
}
.center-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 24em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.content-item--fixed {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    max-width: 50%;
}
.temp {
    margin: 100px auto;
    height: 472px;
    width: 787px;
    transition: all 400ms ease-in;
    opacity: 1;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eceeef;
}