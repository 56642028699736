/*
*
* community
*
*/
.header-community {
    background: url("../images/community.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    float: left;
    width: 100%;
}
.section-calendar {
    width: 100%;
}
.calendar {
    width: 100%;
    padding: 26px 0 20px 0;
}
.social-icon {
    width: 125px;
    height: 142px;
    margin: 0 auto;
}
.twitter {
    border-bottom: 1px solid #ccc;
    width: 650px;
    height: 700px;
    overflow: scroll;
    margin: 0 auto;
}
.strip-2 {
    background: #f3f4f4;
    float: left;
    width: 100%;
}
/*
*
* thank you: https://codepen.io/a-urban/pen/nsbLA
*
*/
.contributor-grid {
	user-select: none
}
.contributor-grid figure {
    position: relative;
    min-width: 200px;
    margin: 5px;
    border: 1px solid #eceeef;
    box-shadow: inset 0 -40px 0 0 rgba(0, 0, 0, 0.3);
    transition: all .2s ease-in-out;
    &:hover {
        box-shadow: inset 0 -250px 0 0 rgba(0, 0, 0, 0.5);
    }
    img {
        filter: brightness(80%);
    }
}
.contributor-grid figcaption {
	position: absolute;
	width: 100%;
	text-align: center;
	color: #f4f4f4;
	bottom: 10px;
	transition: all .2s ease-in-out
}
.contributor-grid figure:hover figcaption {
	bottom: 60px
}
.flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: stretch;
	align-content: stretch
}
.flex-item {
	flex: 1 0 auto;
	max-width: 200px;
}

.github-card {
	border: 1px solid #eee;
    border-radius: 5px;
    padding: 8px 8px 0;
    background: $white;
    float: left;
    width: 380px;
    min-height: 180px;
    margin: 5px;
}
.github-card a {
    text-decoration: none;
    color: #4183c4;
    outline: 0
}
.github-card a:hover {
    text-decoration: underline
}
.github-card .header {
    position: relative
}
.github-card .button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 8px 4px 7px;
    color: #555;
    text-shadow: 0 1px 0 #fff;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 700;
    line-height: 14px;
    background-color: #e6e6e6;
    background-image: -webkit-linear-gradient(#fafafa, #eaeaea);
    background-image: -moz-linear-gradient(#fafafa, #eaeaea);
    background-image: -ms-linear-gradient(#fafafa, #eaeaea);
    background-image: linear-gradient(#fafafa, #eaeaea)
}
.github-card .button:hover {
    color: $white;
    text-decoration: none;
    background-color: #3072b3;
    background-image: -webkit-linear-gradient(#599bdc, #3072b3);
    background-image: -moz-linear-gradient(#599bdc, #3072b3);
    background-image: -ms-linear-gradient(#599bdc, #3072b3);
    background-image: linear-gradient(#599bdc, #3072b3);
    border-color: #518cc6 #518cc6 #2a65a0;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25)
}
.user-card .header {
    padding: 3px 0 4px 57px;
    min-height: 48px
}
.user-card .header a {
    color: #707070;
    text-decoration: none
}
.user-card .header a:hover strong {
    text-decoration: underline
}
.user-card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 4px
}
.user-card strong {
    display: block;
    color: #292f33;
    font-size: 16px;
    line-height: 1.6
}
.user-card ul {
    text-transform: uppercase;
    font-size: 12px;
    color: #707070;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    zoom: 1
}
.user-card ul:after {
    display: block;
    content: '';
    clear: both
}
.user-card .status a {
    color: #707070;
    text-decoration: none
}
.user-card .status a:hover {
    color: #4183c4
}
.user-card .status li {
    float: left;
    padding: 4px 18px;
    border-left: 1px solid #eee
}
.user-card .status li:first-child {
    border-left: 0;
    padding-left: 0
}
.user-card .footer {
    font-size: 12px;
    font-weight: 700;
    padding: 11px 0 10px;
    color: #646464
}
.user-card .footer a {
    color: #646464
}
.repo-card .header {
    padding: 3px 0 4px 57px
}
.repo-card .avatar,
.repo-card .avatar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 4px
}
.repo-card .header a {
    color: #707070
}
.repo-card .header strong {
    display: block;
    font-size: 18px;
    line-height: 1.4
}
.repo-card .header strong a {
    color: #292f33
}
.repo-card .header sup {
    font-size: 10px;
    margin-left: 3px;
    color: #797979
}
.repo-card .content {
    padding: 6px 0 10px
}
.repo-card .content p {
    margin: 0 5px 0 0;
    overflow: hidden;
    clear: both;
    word-wrap: break-word;
    line-height: 20px;
    font-size: 14px;
}
.repo-card .footer {
    border-top: 1px solid #eee;
    padding: 8px 0 6px
}
.repo-card .status {
    font-size: 10px;
    padding-right: 10px;
    text-transform: uppercase
}
.repo-card .status strong {
    font-size: 12px;
    padding-right: 5px
}