/*
*
* moby mobile!
*
*/

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) { 
  .primary-nav,
  .primary-nav-filled {
    background-color: #000000;
  }
  .banner-text p {
    font-size: $base-font-size;
  }
  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: $base-font-size;
  }
  p {
    font-size: 12px!important;
  }
  body.home {
      & p.lead {
        font-size: 12px!important;
      }
  }
.twitter {
    border-bottom: 1px solid #ccc;
    width: 320px;
  }
  nav li {
    padding: 18px 35px 10px 0!important;
    text-align: center;
  }
  .mr-auto {
    margin: 0 auto!important;
  }
}

@media only screen and (max-width: 375px) {

}
