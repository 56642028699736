/*
*
* Buttons ***********************************************************************
*
*/
.btn {
    color: $white;
    background-color: #101e26;
    border: solid 1px #000000;
    padding: .7rem;
    width: 220px;
    &:hover {
        background: $white;
        color: #000;
        border: 1px solid $white;
    }
}
ul.buttons {
    list-style: none;
    width: 450px;
    margin: 0 auto;
}
a.button {
    color: $white;
}
a.button.outline-btn {
    color: #0087C8;
}
.button {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    margin: 10px 10px 10px 0;
    padding: 12px 35px 10px;
    float: left;
    text-align: center;
    width: 100px;
    &:hover {
        color: $white;
        opacity: 0.7;
    }
}
/*
*
* Primary ***********************************************************************
*
*/
.primary-btn {
    background: #1488C6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
/*
*
* Secondary ***********************************************************************
*
*/