/*
*
* primary-navigation
*
*/
.primary-nav {
    background-color: transparent;
    box-shadow: none;
    // height: $nav-height;
    width: $full-width;
    padding: 8px 50px;
    min-height: 64px;
    li {
        display: inline;
        padding: 9px 35px 0 0;
        a {
            font-size: $base-font-size;
            line-height: 1.71;
            text-align: left;
            color: $nav-links;
            text-decoration: none;
        }
    }
}
.primary-nav-filled {
    background-color: $bg-nav;
    box-shadow: $nav-shadow;
    // height: $nav-height;
    width: $full-width;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    // z-index: 9999;
    padding: 8px 50px;
    min-height: 64px;
    li {
        display: inline;
        padding: 9px 35px 0 0;
        a {
            font-size: 14px;
            line-height: 1.71;
            text-align: left;
            color: $nav-links;
            text-decoration: none;
        }
    }
}
.toggle {
    padding: 10px;
}

i.fa.fa-bars,
.fa-caret-down {
    color: $white;
}
button:focus {
    outline: 0;
    outline: 0;
    & i.fa.fa-bars,
    .fa-caret-down {
        color: $white;
    }
}