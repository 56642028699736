/*
*
* base
*
*/
body {
    // background: $bg-body;
    // color: $base-font-color;
    font-family: $font;
    font-size: $base-font-size;
    -webkit-font-smoothing: antialiased;
    font-variant-ligatures: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}
a {
    color: $primary-links;
    text-decoration: underline;
    font-weight: 500;
    outline: none;
    &:hover {
        opacity: .8;
        text-decoration: none;
    }
}
/*
*
* Main Header ***********************************************************************
*
*/
header {
    background-color: #101e26;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1029;
}
/*
*
* Sections + Content ***********************************************************************
*
*/
section {
    padding: 3rem;
    float: left;
    width: 100%;
}

.content {
    padding: 50px 0;
}
/*
*
* Images ***********************************************************************
*
*/
img {
    width: 100%;
    height: auto;
}
/*
*
* Footer ***********************************************************************
*
*/
footer.footer {
    background: $bg-nav;
    min-height: $nav-height;
    width: $full-width;
    padding: 1.3rem 0 1rem 0;
    float: left;
    ul {
        display: inline;
        li {
            display: inline;
            a {
                color: #cfd2d3;
                text-decoration: none;
                font-size: $base-font-size;
                padding: 0 10px 10px 0;
                }
            }
        }
    i.fa {
        font-size: 20px;
    }
    .copyright {
      font-size: 12px;
      color: #9fa5a8;
      width: 500px;
      float: left;
      padding: .5rem 0;
    }
}
.footer-links {
    float: right;
    padding: 0 1rem 0 0;
}
/*
*
* Type is located in _typography.scss ***********************************************************************
*
*/
@font-face {
    font-family: 'Geomanist Bold';
    src: url(/fonts/geomanist/Geomanist-Bold.otf)
}
@font-face {
    font-family: 'Geomanist Medium';
    src: url(/fonts/geomanist/Geomanist-Medium.otf);
}
@font-face {
    font-family: 'Geomanist Light';
    src: url(/fonts/geomanist/Geomanist-Light.otf)
}

@font-face {
    font-family: 'Geomanist Thin';
    src: url(/fonts/geomanist/Geomanist-Thin.otf);
}